import React, { useEffect, useState } from 'react';
import _fetch from '../../config/api';
import { StakeContractAddress, api_url } from '../../config/Config';
import toasted from '../../config/toast';
import Loader from '../../Layout/Loader';
import LoaderDyn from '../../Layout/LoaderDyn';

const StakeCoin = (props) => {
  const [amount, setAmount] = useState("");
  const [getSopnserWallet, setgetSopnserWallet] = useState('');
  const [gethash, setGethash] = useState('');

  const [approveLoader, setapproveLoader] = useState('0');
  const [changeSection, setchangeSection] = useState(false);
  const [activeLogin, setactiveLogin] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);


  const approveAndSendTokens = async () => {
    if (amount >= 50) {
      setbtnDisable(true)

      const web3 = props?.maskData?.web3;
      const account = props?.maskData?.address;
      const tokenContract = props?.maskData?.tokenContract;
      const StakeContract = props?.maskData?.StakeContract;
      // console.log('web3', web3);
      // console.log('account', account);
      // console.log('tokenContract', tokenContract);
      // console.log('StakeContract', StakeContract);

      if (web3 && account && tokenContract) {
        setapproveLoader('1')

        const approvalAmount = web3.utils.toWei(`${amount}`, 'ether'); // Replace with the desired approval amount
        try {
          const _approvalGas = await tokenContract.methods.approve(StakeContractAddress, approvalAmount).estimateGas({
            from: account,
          });


          const approvalGas = await parseInt(_approvalGas.toString().replace("n", ""));
          const _gasPrice = await web3.eth.getGasPrice();
          const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));
          // console.log('gasPrice sadasd', gasPrice, approvalAmount);

          const gasPriceGwei = gasPrice;
          const gasPriceWei = Math.floor(gasPriceGwei * 1.3);

          const approvalClear = await tokenContract.methods.approve(StakeContractAddress, approvalAmount).send({
            from: account,
            gasPrice: gasPriceWei.toString(),
            gasLimit: approvalGas
          }).once('transactionHash', function (hash) {
            // console.log('approval hash: ', hash);

          }).on('error', function (error) {
            // console.log('\n[ERROR]\n\n' + error
            //   .message);
            let msg = error.message.split(":");
            // toastr.error("Error " + error.code +
            //     " : " + msg[1]);
          }).then(async function (receipt) {
            setapproveLoader('2')
            setchangeSection(false)

            const getEstimatedGas = await StakeContract.methods.stake(approvalAmount, sponserAddress, props?.user_wallet).estimateGas({ from: account });
            const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));

            // console.log('gasPrice', gasPrice, estimatedGas, approvalAmount);
            const _gasPriceGwei = gasPrice;
            const _gasPriceWei = Math.floor(_gasPriceGwei);

            await StakeContract.methods.stake(approvalAmount.toString(), sponserAddress, props?.user_wallet).send({
              from: account,
              gasPrice: _gasPriceWei.toString(),
              gasLimit: estimatedGas
            }).once('transactionHash', function (hash) {
              // console.log('transactionHash hash: ', hash);

              // console.log(hash);
              // setGethash(hash)
              setchangeSection(true)
            }).on('error', function (error) {
              // console.log('\n[ERROR]\n\n' + error
              //   .message);
              let msg = error.message.split(":");
              // toastr.error("Error " + error.code +
              //     " : " + msg[1]);
            }).then(async function (receipt) {
              // call node apiLOG

              // console.log('receipt', receipt);
              StakeAmount(receipt.transactionHash)
              setactiveLogin(true)
              // setGethash(receipt.transactionHash)

            })
          })

          // console.log('Tokens approved and sent successfully');
        } catch (error) {
          // console.error('Error approving and sending tokens:', error);
          toasted.error(error?.data?.message);
          setbtnDisable(false)
          setapproveLoader('3')


        }
      }
    } else {
      toasted.error('Please enter amount minimum $50');

    }
  };

  const StakeAmount = async (hash) => {
    let data = {
      "username": props.userName,
      "amount": parseInt(amount),
      "investCode": props.package,
      "hash": hash,
      'eth_address': props?.user_wallet,

    }

    let res = await _fetch(`${api_url}investment/invest-now`, "POST", data)
    if (res?.status === 'success') {
      toasted.success(res?.message);
      setbtnDisable(false)
      props.onCloseModal5();
      setapproveLoader('3')
      window.location.reload();
      // Navigate("/dashboard");
    }
    else {
      toasted.error(res?.message);
      setbtnDisable(false)

      setapproveLoader('3')

    }
  }

  const [sponserAddress, SetsponserAddress] = useState();

  const getSponserInfo = async () => {
    let res = await _fetch(`${api_url}user/sponserInfo/`, "GET", {})
    if (res?.status === 'success') {
      SetsponserAddress(res?.data?.wallet_address);
      // console.log('res?.data?.data sponser',res?.data.wallet_address);
    }
  }


  useEffect(() => {
    getSponserInfo();

  }, [])


  return (
    <>

      <div className='vista_sec'>
        <h5>Stake VISTA </h5>
        <p className='mt-3'>Amount (BEP20-USDT)</p>
        <input type="number" placeholder='minimum 50 USDT' onChange={(e) => setAmount(e.target.value)} value={amount} />
        <p className='mt-3'>Amount (VISTA)</p>
        <input type="number" placeholder='' value={(parseInt(amount) / props?.coinPrice)?.toFixed(3)} />
        <div className='stake_btn'>


          <button className='button_style' onClick={approveAndSendTokens} disabled={btnDisable}>
            {btnDisable === true &&
              approveLoader == 1 ? <LoaderDyn text={'Wating for approval'} /> : approveLoader == 2 ? <LoaderDyn text={'Wating for Confirmation'} /> : ''
            }
            {btnDisable === false && 'Stake VISTA '}



          </button>
        </div>
      </div>
    </>
  );
}

export default StakeCoin;
