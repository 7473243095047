import React from 'react';
import './Assets/Scss/Style.scss';
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";

import Register from './Public/Users/Register';
import Forgot from './Public/Users/Forgot';
import Dashboard from './Public/HomePage/Dashboard';
import Profile from './Public/HomePage/Profile';
import Trading from './Public/HomePage/Trading';
import Team from './Public/HomePage/Team';
import Ledger from './Public/HomePage/Ledger';
import PayHistory from './Public/HomePage/PayHistory';
import ArchieverHistory from './Public/HomePage/ArchieverHistory';
import RankRoyalty from './Public/HomePage/RankRoyalty';
import Globalpool from './Public/HomePage/Globalpool';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Market from './Private/Market';
import { ReStakeContractAbi, ReStakeContractAddress, StakeContractAbi, StakeContractAddress, WithdrawContractAbi, WithdrawContractAddress, api_url, contractAbi, contractAddress, sellContractAbi, sellContractAddress, supportedChainId, titleDescription } from './config/Config';
import Sidebar from './Layout/Sidebar';
import PrivateRoutes from './config/PrivateRoutes';
import BuyInfra from './Public/HomePage/BuyInfra';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UniLevel from './Public/HomePage/UniLevel';
import Cashflow from './Public/HomePage/Cashflow';
import Presentations from './Public/HomePage/Presentations';
import Support from './Public/HomePage/Support';
import ExpoArchivers from './Public/HomePage/ExpoArchivers';
import Demo from './Public/Demo';
import TicketView from './Public/HomePage/TicketView';
import PayoutRequest from './Public/HomePage/PayoutRequest';
import DirectIncome from './Public/HomePage/directIncome';
import DappLogin from './Public/Users/DappLogin';
import { useEffect, useState } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import StakeHistory from './Public/HomePage/StakeHistory';
import BusinessLevel from './Public/HomePage/BusinessLevel';
import TreeView from './Public/HomePage/TreeView';
import AdminSideLogin from './Public/Users/AdminSideLogin';
import AlmateryBuniness from './Public/HomePage/AlmateryBusiness';
import EditArchiever from './Public/HomePage/EditArchiever';
function App() {

  // ? Login page.....
  function LoginLayout() {
    return (
      <>
        <Outlet />
      </>
    );
  }

  // Public page.....
  function PublicLayout() {
    return (
      <>
        <Sidebar account={account} />
        <Header />

        <Outlet />
        <Footer />

      </>
    );
  }

  // Only Private page.....
  function PrivateLayout() {
    return (
      <>
        <Sidebar />
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  }

  ////////////////// dapp things //////////

  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [disconnectStataus, setdisconnectStataus] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [StakeContract, setStakeContract] = useState(null);
  const [ReStakeContract, setReStakeContract] = useState(null);
  const [WithdrawContract, setWithdrawContract] = useState(null);
  const [sellContract, setsellContract] = useState(null);


  const [leftCoinBalance, setleftCoinBalance] = useState(0);

  const [web3Useff, setWeb3UseEff] = useState(0);

  const connectWallet = async () => {
    // console.log('asdasd', web3);
    setdisconnectStataus(false)

    // setWeb3UseEff(web3Useff ==0 ? 1:0);
    // setdisconnectStataus(false)
    if (web3) {
      try {
        web3.eth.getChainId().then(async chainIdd => {
          // console.log(chainIdd)
          const chainId = chainIdd.toString().slice(0, -1);
          const hexChainId = await web3.utils.toHex(chainId);
          const checkChain = await supportedChainId.includes(hexChainId);
          // console.log(checkChain, hexChainId);
          // const newChain = chainId.toString().slice(0,-1);
          if (checkChain === false) {
            // console.log("test");
            switchNetwork(supportedChainId[0])
          }
        }

        );

        // console.log('chainId',chainId);
        await web3.currentProvider.enable(); // Prompt user to connect
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          // console.log('accounts[0]',accounts[0]);
          getCoinBalances(web3, accounts);
          
          getTokenBalances(web3, accounts);
          // console.log(formattedCoinBalance);
        }
        // setAccount(accounts[0]);
      } catch (error) {
        // console.error('Error connecting wallet:', error);
      }
    }
  };

  const switchNetwork = async (receiveChainId) => {
    if (web3) {
      try {
        // console.log(receiveChainId);
        await web3.currentProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: receiveChainId.toString(), // BSC Testnet chain ID
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'], // Testnet RPC URL
              blockExplorerUrls: ['https://bscscan.com'], // Testnet explorer URL
            },
          ],
        });

        // The user might need to reconnect their wallet after switching networks
      } catch (error) {
        // console.error('Error switching network:', error);
      }
    }
  };



  const getCoinBalances = async (web3Instance, accounts) => {
    const getCoinBalance = await web3Instance.eth.getBalance(accounts[0]);
    const formattedCoinBalance = web3Instance.utils.fromWei(getCoinBalance, 'ether');
    
    if (formattedCoinBalance > 0) {
      setCoinBalance(formattedCoinBalance);
    }
  }

  const getTokenBalances = async (web3Instance, accounts) => {
    const contract = new web3Instance.eth.Contract(contractAbi, contractAddress);
    setTokenContract(contract);

    // const currency = await contract.methods.symbol().call();
    const getTokenBalance = await contract.methods.balanceOf(accounts[0]).call();
    const tokenDecimals = await contract.methods.decimals().call();
    const tokenDecimalsMain = await parseInt(tokenDecimals.toString().replace("n", ""));
    const formattedBalance = getTokenBalance.toString().replace("n", "") / (10 ** tokenDecimalsMain);

    if (formattedBalance > 0) {
      setTokenBalance(formattedBalance);
    }



    const tokenContractInstance = new web3Instance.eth.Contract(StakeContractAbi, StakeContractAddress);
    // console.log(tokenContractInstance);
    setStakeContract(tokenContractInstance);

    const RestaketokenContractInstance = new web3Instance.eth.Contract(ReStakeContractAbi, ReStakeContractAddress);
    const _balance = await RestaketokenContractInstance.methods.balanceOf(StakeContractAddress).call();
    const leftVista = _balance.toString().replace("n", "") / (10 ** 18);
    setleftCoinBalance(leftVista);
    setReStakeContract(RestaketokenContractInstance);
    const withdrawContractInstance = new web3Instance.eth.Contract(WithdrawContractAbi, WithdrawContractAddress);

    setWithdrawContract(withdrawContractInstance);
    const sellContractInstance = new web3Instance.eth.Contract(sellContractAbi, sellContractAddress);

    setsellContract(sellContractInstance);
  }



  const passprops = {
    coinBalance: parseFloat(coinBalance).toFixed(3),
    tokenBalance: parseFloat(tokenBalance).toFixed(3),
    address: account,
    // approveLoader: approveLoader,
    // changeSection: changeSection,
    // activeLogin: activeLogin,
    // setgetSopnser: setgetSopnser,
    // getSopnser: getSopnser,
    web3: web3,
    tokenContract: tokenContract,
    StakeContract: StakeContract,
    ReStakeContract: ReStakeContract,
    leftCoinBalance: leftCoinBalance,
    WithdrawContract: WithdrawContract,
    sellContract: sellContract

    // setgetSopnserWallet: setgetSopnserWallet,
  }
  localStorage.setItem('leftCoinBalance', leftCoinBalance);

  useEffect(() => {

    // registerUser()

    connectWallet();
    if (disconnectStataus !== true) {
      const loadWeb3 = async () => {
        const provider = await detectEthereumProvider() || new InjectedConnector({ supportedChainIds: supportedChainId });

        if (provider) {
          if (provider.isMetaMask) {
            provider.autoRefreshOnNetworkChange = false;
          }
          // console.log('set web 3');
          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);

          // 

          provider.on('accountsChanged', (newAccounts) => {
            if (newAccounts.length === 0) {
              // User disconnected wallet
              // setWeb3(null);
              setAccount(null);
            } else {
              setAccount(newAccounts[0]);
              // console.log('newAccounts[0]',newAccounts[0]);
              // getCoinBalances(web3Instance, newAccounts);
              // getTokenBalances(web3Instance, accounts);


            }
          });

          // 

          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            // console.log('accounts[0]',accounts[0]);
            // getCoinBalances(web3Instance, accounts);
            // getTokenBalances(web3Instance, accounts);
            // console.log(formattedCoinBalance);
          }


        }
      };

      loadWeb3();
    }

  }, [account, disconnectStataus]);

  const disconnectWallet = async () => {
    // console.log('dis', web3);
    localStorage.clear();

    // if (web3) {
    //   web3.providers.HttpProvider.prototype.disconnect();
    setWeb3(null);
    setAccount(null);
    // }
    setdisconnectStataus(true)

    //     const ethereum = window.ethereum
    //       if (ethereum) {
    //       // Listening to Event
    //       ethereum.on('disconnect', () => {
    // console.log("MetaMask discconnected")
    //         // this.setState({ metamaskHasDisonnected: true })
    //   })
    // }
  };


  return (
    <>

      <Router>
        <Routes>


          {/* header and footer is removed this layout */}
          <Route element={<LoginLayout />}>
            {/* <Route path='*' element={<Index pageTitle={`${titleDescription}`} />} /> */}
            {/* <Route path='*' element={<Login pageTitle={`Login | ${titleDescription}`} />} /> */}
            <Route path='*' element={<DappLogin pageTitle={`Login | ${titleDescription}`} address={account} disconnectWallet={disconnectWallet} connectWallet={connectWallet} />} />

            <Route path='/userLogin/:address' element={<AdminSideLogin pageTitle={`Login | ${titleDescription}`} address={account} disconnectWallet={disconnectWallet} connectWallet={connectWallet} />} />

            {/* <Route path='/login' element={<Login pageTitle={`Login | ${titleDescription}`} />} /> */}
            <Route path='/register' element={<Register pageTitle={`Register | ${titleDescription}`} address={account} disconnectWallet={disconnectWallet} connectWallet={connectWallet} />} />
            <Route path='/register/:sponser' element={<Register pageTitle={`Register | ${titleDescription}`} address={account} disconnectWallet={disconnectWallet} connectWallet={connectWallet} />} />
            <Route path='/forgot' element={<Forgot pageTitle={`Forgot | ${titleDescription}`} />} />
            <Route path='/demo' element={<Demo />} />
          </Route>

          {/* All Public pages___ */}
          <Route element={<PrivateRoutes />}>
            <Route element={<PublicLayout />}>
              <Route path='/dashboard' element={<Dashboard pageTitle={`Dashboard | ${titleDescription}`} maskData={passprops} />} />
              <Route path='/profile' element={<Profile pageTitle={`Profile | ${titleDescription}`} />} />
              {/* <Route path='/deposit' element={<Deposit pageTitle={`Deposit | ${titleDescription}`} />} /> */}
              <Route path='/trading-profit' element={<Trading pageTitle={`Trading | ${titleDescription}`} />} />
              <Route path='/buy-infra' element={<BuyInfra pageTitle={`Buy Infra | ${titleDescription}`} />} />
              <Route path='/team' element={<Team pageTitle={`Team | ${titleDescription}`} />} />
              <Route path='/team/:username' element={<TreeView pageTitle={`Team | ${titleDescription}`} />} />
              <Route path='/business' element={<BusinessLevel pageTitle={`Business | ${titleDescription}`} />} />
              <Route path='/almaterybusiness' element={<AlmateryBuniness pageTitle={`Business | ${titleDescription}`} />} />
              <Route path='/uni-level' element={<UniLevel pageTitle={`Uni Level | ${titleDescription}`} />} />
              <Route path='/report/:type' element={<DirectIncome pageTitle={`Direct Income | ${titleDescription}`} />} />
              <Route path='/stake-report/:type' element={<StakeHistory pageTitle={`Stake History | ${titleDescription}`} />} />

              <Route path='/cashflow' element={<Cashflow pageTitle={`Cashflow | ${titleDescription}`} />} />
              {/* <Route path='/overview' element={<Overview pageTitle={`Overview | ${titleDescription}`} />} /> */}
              {/* <Route path='/presentations' element={<Presentations pageTitle={`Presentations | ${titleDescription}`} />} /> */}
              <Route path='/ledger' element={<Ledger pageTitle={`Ledger | ${titleDescription}`} />} />
              <Route path='/pay-history' element={<PayHistory pageTitle={`Pay History | ${titleDescription}`} />} />
              <Route path='/rankroyalty' element={<RankRoyalty pageTitle={`Rank Royalty | ${titleDescription}`} />} />
              <Route path='/globalpool' element={<Globalpool pageTitle={`Globalpool | ${titleDescription}`} />} />
              <Route path='/create-ticket' element={<Support pageTitle={`Support | ${titleDescription}`} />} />
              <Route path='/ticket-history' element={<TicketView pageTitle={`Ticket View | ${titleDescription}`} />} />
              <Route path='/payout-request' element={<PayoutRequest pageTitle={`Payout Request | ${titleDescription}`} maskData={passprops} />} />
              <Route path='/create-archiever' element={<ExpoArchivers pageTitle={`ExpoArchivers | ${titleDescription}`} />} />
              <Route path='/archiever-history' element={<ArchieverHistory pageTitle={`Archiever History | ${titleDescription}`} />} />
              <Route path='/edit-archiever/:phoneNumber' element={<EditArchiever pageTitle={`EditArchivers | ${titleDescription}`} />} />
            </Route>
          </Route>

          {/* All Public pages___ */}
          <Route element={<PrivateLayout />}>
            <Route path='/market' element={<Market pageTitle={`Market | ${titleDescription}`} />} />
          </Route>



        </Routes>
      </Router >

      <ToastContainer />
    </>
  );
}

export default App;
