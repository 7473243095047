import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { api_url, logo, title } from '../../config/Config';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import Loader from '../../Layout/Loader';





const DappLogin = (props: any) => {


    // console.log('login address', props.address);
    const [btnDisable, setbtnDisable] = useState(false);


    useEffect(() => {
        document.title = `${props.pageTitle}`;
    }, [props?.address]);

    const [walletAddress, setWalletAddress] = useState('');

    const Navigate = useNavigate();

    const submitLogin = async (e: any) => {
        setbtnDisable(true)
        e.preventDefault();
        let data = {
            // "wallet_address": '0xA5037D4dd46B35bD05a657fF49e8BcB604808e41',
            // "wallet_address": '0x985FB4b54Ce0edcFF8eB660dC25A5a0a5D3c4686',
           
            
            "wallet_address": props?.address,
            //   "wallet_address" : '0x6020601b82374FB99a77C7ad861e7365e82b42a7',
            //   "wallet_address" : '0x6020601b82374FB99a77C7ad861e7365e82b42a7',
            //   "wallet_address" : '0xfc6609ca49fe3c3820248d804a1bb0e5fc99ebbb',
              
        }
        let res = await _fetch(`${api_url}auth/login`, "POST", data, {})
        // if(selectCheckbox) {

        // if(res?.status === 'success') {
        // console.log(res);

        // if(selectCheckbox) {

        if (res?.status === 'success') {

            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", res?.token);
            localStorage.setItem("user", JSON.stringify(res?.data?.user));
            toasted.success(res?.message);
            Navigate("/dashboard");
            setbtnDisable(false)

        }
        else {
            localStorage.clear();
            toasted.error(res?.message);
            console.log(res?.message);
            // setbtnDisable(true)
            setbtnDisable(false)



            // }
        }
        // else {
        //   toasted.error("Please select checkbox");
        // }
        // } 
    }

    const connectLogin = async () => {
        let data = {
            "wallet_address": walletAddress
        }
        let res = await _fetch(`${api_url}auth/login`, "POST", data)
        if (res?.status === "success") {
            toasted.success(res?.message);
            Navigate("/dashboard");
        }
        else {
            toasted.error(res?.message);
        }
    }

    return (
        <>

            <div className="login-body">
                <div className="empty">
                    <div className="coustom_container">
                        <Row className="form-row">
                            <Col xl={12} lg={12} md={12} className="login-col">
                                <div className="waves">
                                    <div className="form-outer">
                                        <div className="form-inner">
                                            <div className="form-head">
                                                <img width="170px" src={logo} alt={title + `logo.png`} />
                                                <h2>Login</h2>
                                                <p>Sign into your account</p>

                                                {/* <div className='wallet-sec'> */}
                                                {props?.address ? <button className='btn btn-success connecte-btn' onClick={props.connectWallet}>Connected Wallet</button> : <button className='btn btn-success connecte-btn' onClick={props.connectWallet}>Connect Wallet</button>}

                                                {/* </div> */}

                                            </div>
                                            <form className="form-start" onSubmit={submitLogin}>
                                                <input type="text" id="email" placeholder="Wallet Address"
                                                    // value={props?.address} 
                                                    value={props?.address}
                                                    readOnly />


                                                <button type="submit" className="log-btn" disabled={btnDisable}>
                                                    {btnDisable === true &&
                                                        <Loader />
                                                    }
                                                    {btnDisable === false && 'Login '}

                                                </button>
                                                <div className="form-para">
                                                    <p>
                                                        Don't have an account? <Link to="/register">Register here</Link>
                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DappLogin;
