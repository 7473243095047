import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Row, Col } from "react-bootstrap";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EmailIcon from "@mui/icons-material/Email";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from "react-bootstrap/Table";
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from "../../config/api";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import '../../Assets/Scss/tree.scss';
import userActive from "../../Assets/Image/userIconactive.png"
import userInactive from "../../Assets/Image/userIconinactive.png"
import { Link, useParams } from "react-router-dom";
import toasted from "../../config/toast";

const TreeView = (props: any) => {

  const { username } = useParams();

  useEffect(() => {
    document.title = `${props.pageTitle}`;
  });

  const [loading, setLoading] = useState(true);

  const initialDirectTeamTree = {
    name: '',
    package: 0,
    username: '',
    direct_business: 0,
    rank: '',
    directs: [
      {
        directanotherDirects: []
      }
    ]
  };

  const [directTeamTree, setDirectTeamTree] = useState(initialDirectTeamTree);

  useEffect(() => {
    getAllTeamTree();
  }, [username]);

  // const refresh = () => window.location.reload();

  const getAllTeamTree = async () => {
    setLoading(true);
    setDirectTeamTree(initialDirectTeamTree);

    let data = await _fetch(`${api_url}network/direct-tree?username=${username?.toLocaleLowerCase()}`, 'GET', {})

    if (data?.status === 'success') {
      
      setDirectTeamTree(data?.data?.[0]);
      setLoading(false);
    }else{
      setLoading(false);
      toasted.error(data?.message);

    }
  }
  const refresh = () => window.location.reload();
  return (
    <main>
      <div className="team-section">
        <div className="coustom_container">
          <div className="team-outer  loader_main">
            {loading &&
              <div className="spinner_bg">

                <div className="color_ring">  <ColorRing
                  visible={true}
                  height="80"
                  width="80"

                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                /></div>

              </div>
            }

            <Tabs>
              <Row>
                <Col xl={12} >
                  <div className="team-inner">
                    <Row className="team_row">
                    </Row>
                    <div className="panel">

                      <TabPanel>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card mt-5 main-tree">
                              <div className="card-body">
                                {/* <h6> Comming soon </h6> */}

                                <ul className="tree vertical ">
                                  <li>

                                    <div className="user-main-sec">
                                      <div className="user">
                                        <div className="user-img">
                                          <img className="img-sec" src={directTeamTree?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                        </div>
                                        <p>  {directTeamTree?.name !== 'null null' ? directTeamTree?.name : 'No Name'} </p>
                                      </div>

                                      <div className="dropdown-sec">
                                        <p> User ID : <span> { directTeamTree?.username ? `${directTeamTree?.username?.substring(0, 4)}...${directTeamTree?.username?.substring(directTeamTree?.username?.length - 4)}` : ''} </span> </p>
                                        <p>  User Name : <span> {directTeamTree?.name !== 'null null' ? directTeamTree?.name : 'No Name'} </span> </p>
                                        <p>  Investment : <span> {directTeamTree?.package ? directTeamTree?.package : "0"}</span> </p>
                                        <p>  Total Business : <span> {directTeamTree?.direct_business ? directTeamTree?.direct_business : '0'} </span> </p>
                                        <p>  Rank : <span> {directTeamTree?.rank} </span> </p>
                                      </div>
                                    </div>

                                    <ul className="inner-team">
                                      {directTeamTree?.directs?.map((item: any, key: any) => {
                                        return (
                                          <>
                                            <li key={key} >
                                              <div className="user-main-sec">
                                                <div className="user">
                                                  <div className="user-img">
                                                    <img className="img-sec" src={item?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                                  </div>
                                                  <p>  {item?.name !== 'null null' ? item?.name : 'No Name'} </p>
                                                </div>

                                                <div className="dropdown-sec">
                                                  <Link to={`/team/${item?.referalUsername}`}> User ID : <span> {item?.referalUsername ? `${item?.referalUsername?.substring(0, 4)}...${item?.referalUsername?.substring(item?.referalUsername?.length - 4)}` : ""} </span> </Link>
                                                  <p>   User Name : <span> {item?.name !== 'null null' ? item?.name : 'No Name'} </span> </p>
                                                  <p>  Investment : <span> {item?.package ? item?.package : "0"}</span> </p>
                                                  <p>  Total Business : <span> {item?.direct_business ? item?.direct_business : '0'} </span> </p>
                                                  <p>  Rank : <span> {item?.rank} </span> </p>
                                                </div>
                                              </div>
                                              <ul>
                                                {item?.directanotherDirects?.map((inneritem: any, index: any) => {

                                                  return (
                                                    <>
                                                      <li>
                                                        <div className="user-main-sec">
                                                          <div className="user">
                                                            <div className="user-img">
                                                              <img className="img-sec" src={inneritem?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                                            </div>
                                                            <p> {inneritem?.name !== 'null null' ? inneritem?.name : 'No Name'} </p>
                                                          </div>

                                                          <div className="dropdown-sec">
                                                          <Link to={`/team/${inneritem?.username}`}> User ID : <span> {inneritem?.username ? `${inneritem?.username?.substring(0, 4)}...${inneritem?.username?.substring(inneritem?.username?.length - 4)}` : ""} </span> </Link>
                                                            <p>   User Name : <span> {inneritem?.name !== 'null null' ? inneritem?.name : 'No Name'}</span> </p>
                                                            <p> Investment : <span> {inneritem?.package ? inneritem?.package : '0'}</span> </p>
                                                            <p>  Total Business : <span> {inneritem?.direct_business ? inneritem?.direct_business + inneritem?.team_business : '0'} </span> </p>
                                                            <p>  Rank : <span> {inneritem?.rank} </span> </p>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  )
                                                })}
                                              </ul>
                                            </li>
                                          </>
                                        )
                                      })}
                                    </ul>
                                  </li>
                                </ul>

                              </div>

                            </div>
                          </div>
                        </div>

                      </TabPanel>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tabs>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TreeView;
